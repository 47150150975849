
<template>
    <CCard>
        <InsightModal
            :showModal.sync="InsightModal"
            :item.sync="InsightObj"
            @InsightModal-updated="InsightModal = $event"
            :viewmode="'view'"
            :viewer="true"
        />

        <IdeaModal
            :showModal.sync="IdeaModal"
            @IdeaModal-updated="IdeaModal = $event"
            :viewmode="'view'"
            :insightDataSelected="InsightSelected"
            @insightListSelected-updated="InsightSelected = $event"
            :item.sync="ideaObject"
            :onlyView="true"
            :viewer="true"
        />

        <ConceptModal
            :showModal.sync="ConceptModal"
            @ConceptModal-updated="ConceptModal = $event"
            :item.sync="conceptObj"
            :viewmode="'view'"
            :viewer="true"
        />
    </CCard>
</template>

<script>

import InsightModal from "../insight/InsightModal";
import IdeaModal from "../idea/IdeaModal";
import ConceptModal from "../concept/ConceptModal";

export default {
    name: "Viewer",
    components: { InsightModal, IdeaModal, ConceptModal },
    data() {
        return {
            InsightModal: false,
            InsightObj: null,
            IdeaModal: false,
            ideaObject: null,
            InsightSelected: [],
            ConceptModal: false,
            conceptObj: null,
        };
    },
    mounted() {
        let createNo = this.$route.params.docNo || "";
        if (createNo !== "") {
            let type = createNo.substring(0, 3);
            switch (type) {
                case "INS":
                    this.InsightObj = { InsightNo : createNo };
                    this.InsightModal = true;
                    break;
                case "IDE":
                    this.ideaObject = { IdeaNo : createNo };
                    this.IdeaModal = true;
                    break;
                case "CON":
                    this.conceptObj = { ConceptNo : createNo };
                    this.ConceptModal = true;
                    break;
            }
        }
    }
};
</script>